<template>
	<v-navigation-drawer
		id="core-navigation-drawer"
		v-model="drawer"
		:dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
		:expand-on-hover="expandOnHover"
		:right="$vuetify.rtl"
		:src="barImage"
		mobile-breakpoint="960"
		app
		width="260"
		v-bind="$attrs"
	>
		<template v-slot:img="props">
			<v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
		</template>
		<v-divider class="mb-1" />

		<v-list dense nav>
			<v-list-item>
				<v-list-item-avatar class="align-self-center" color="white" contain>
					<v-img src="@/assets/logo.png" max-height="30" />
				</v-list-item-avatar>

				<v-list-item-content>
					<v-list-item-title class="display-1" v-text="profile.title" />
				</v-list-item-content>
			</v-list-item>
		</v-list>

		<v-divider class="mb-2" />

		<v-list expand nav>
			<!-- Style cascading bug  -->
			<!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
			<div />

			<template v-for="(item, i) in computedItems">
				<base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
					<!--  -->
				</base-item-group>

				<base-item v-else :key="`item-${i}`" :item="item" />
			</template>

			<!-- Style cascading bug  -->
			<!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
			<div />
		</v-list>

		<template v-slot:append>
			<!-- <base-item
        :item="{
          title: $t('upgrade'),
          icon: 'mdi-package-up',
          to: '/upgrade',
        }"
      /> -->
		</template>
	</v-navigation-drawer>
</template>

<script>
	// Utilities
	import { mapState } from "vuex";

	export default {
		name: "DashboardCoreDrawer",

		props: {
			expandOnHover: {
				type: Boolean,
				default: false,
			},
		},

		data: () => ({
			
			items: [
				{
					icon: "far fa-folder",
					title: "Catálogos",
					roles_permitidos: [1,2],
					children: [
						{
							icon: "fas fa-credit-card",
							title: "Cuentas",
							to: "/dashboard/catalogos/cuentas",
							roles_permitidos: [1,2]
						},
						{
							icon: "mdi-factory",
							title: "Emisores",
							to: "/dashboard/catalogos/emisores",
							roles_permitidos: [1,2]
						},
						{
							icon: " mdi-bank",
							title: "Impuestos",
							to: "/dashboard/catalogos/impuestos",
							roles_permitidos: [1,2]
						},
						{
							icon: "mdi-account-multiple",
							title: "Usuarios",
							to: "/dashboard/acl/usuarios",
							roles_permitidos: [1,2]
						},
						{
							icon: "mdi-currency-usd",
							title: "Listas",
							to: "/dashboard/catalogos/listas",
							roles_permitidos: [1,2]
						},
						{
							icon: 'mdi-currency-usd',
							title: 'Motivos No Visita',
							to: '/dashboard/catalogos/motivos_no_visita',
							roles_permitidos: [1,2]
						},
						{
							icon: 'mdi-currency-usd',
							title: 'Rutas',
							to: '/dashboard/catalogos/rutas',
							roles_permitidos: [1,2]
						}
					],
				},
				{
					icon: "mdi-basket",
					title: "Productos",
					roles_permitidos: [1,2],
					children: [
						{
							icon: "mdi-view-dashboard",
							title: "Familias",
							to: "/dashboard/catalogos/productos-familias",
							roles_permitidos: [1,2]
						},
						{
							icon: "mdi-view-dashboard",
							title: "Sub-familias",
							to: "/dashboard/catalogos/productos-subfamilias",
							roles_permitidos: [1,2]
						},
						{
							icon: "mdi-view-dashboard",
							title: "Productos",
							to: "/dashboard/catalogos/productos",
							roles_permitidos: [1,2]
						},
					],
				},
				{
					icon: "mdi-account-network",
					title: "Vendedores",
					roles_permitidos: [1,2],
					children: [
						{
							icon: "mdi-view-dashboard",
							title: "Categorías",
							to: "/dashboard/catalogos/vendedores-categorias",
							roles_permitidos: [1,2]
						},
						{
							icon: "mdi-view-dashboard",
							title: "Subcategorías",
							to: "/dashboard/catalogos/vendedores-subcategorias",
							roles_permitidos: [1,2]
						},
						{
							icon: "mdi-view-dashboard",
							title: "Vendedores",
							to: "/dashboard/catalogos/vendedores/",
							roles_permitidos: [1,2]
						},
					],
				},
				{
					icon: "mdi-account-multiple",
					title: "Clientes",
					roles_permitidos: [1,2],
					children: [
						{
							icon: "mdi-view-dashboard",
							title: "Categorías",
							to: "/dashboard/catalogos/clientes-categorias",
							roles_permitidos: [1,2]
						},
						{
							icon: "mdi-view-dashboard",
							title: "Clientes",
							to: "/dashboard/catalogos/clientes/",
							roles_permitidos: [1,2]
						},
					],
				},
				{
					icon: "mdi-account-network",
					title: "Almacenes",
					roles_permitidos: [1,2,3,4,5],
					children: [
						{
							icon: "mdi-view-dashboard",
							title: "Carga / Descarga de Productos",
							to: "/dashboard/almacenes/carga-descarga",
							roles_permitidos: [1,2,3,4,5]
						},
						{
							icon: "mdi-view-dashboard",
							title: "Devolución de Productos",
							to: "/dashboard/almacenes/devoluciones",
							roles_permitidos: [1,2,3,4,5]
						},
					],
				},
				{
					icon: "mdi-view-dashboard",
					title: "Metas",
					to: "/dashboard/metas",
					roles_permitidos: [1,2]
				},
				{
					icon: "mdi-file",
					title: "Documentos",
					to: "/dashboard/documentos",
					roles_permitidos: [1,2]
				},
				{
					icon: "fas fa-file-invoice",
					title: "Reportes",
					roles_permitidos: [1,2,3,4,5],
					children: [
						{
							roles_permitidos: [1,2,3,4,5],
							title: "Ventas",
							children: [
								{
									title: "General",
									to: "/dashboard/reportes/ventas/general",
									roles_permitidos: [1,2,3,4,5],
								},
								{
									title: "CxC",
									to: "/dashboard/reportes/ventas/cxc",
									roles_permitidos: [1,2]
								},
								{
									title: "Sell In - Sell Out",
									roles_permitidos: [1,2,3,4,5],
									children: [
										{
											title: "Detallado",
											to: "/dashboard/reportes/ventas/sellin-sellout/detallado",
											roles_permitidos: [1,2,3,4,5],
										},
										{
											title: "Sell In",
											to: "/dashboard/reportes/ventas/sellin-sellout/sell-in",
											roles_permitidos: [1,2,3,4,5],
										},
										{
											title: "Sell Out",
											to: "/dashboard/reportes/ventas/sellin-sellout/sell-out",
											roles_permitidos: [1,2,3,4,5],
										},
									]
								},
							],
						},
						{
							title: "Facturas",
							roles_permitidos: [1,2,3,4,5],
							children: [
								{
									title: "General",
									to: "/dashboard/reportes/facturas/general",
									roles_permitidos: [1,2,3,4,5]
								},
							],
						},
						{
							title: "Vendedores",
							roles_permitidos: [1,2,3,4,5],
							children: [
								{
									title: "Almacenes",
									to: "/dashboard/reportes/vendedores/almacenes",
									roles_permitidos: [1,2,3,4,5]
								},
								{
									title: "Devoluciones",
									to: "/dashboard/reportes/devoluciones",
									roles_permitidos: [1,2,3,4,5]
								},
							],
						},
						{
							title: "Rutas",
							roles_permitidos: [1,2,3,4,5],
							children: [
								{
									title: "General",
									to: "/dashboard/reportes/rutas/general",
									roles_permitidos: [1,2,3,4,5]
								},
								{
								title: "Horas Visitas",
								to: "/dashboard/reportes/rutas/horas_visitas",
								roles_permitidos: [1,2,3,5]
								},
								{
								title: "No Visitas",
								to: "/dashboard/reportes/rutas/no_visitas",
								roles_permitidos: [1,2,3,5]
								},
							],
						},
					],
				},
				{
					icon: "fas fa-bell",
					title: "Notificaciones",
					to: "/dashboard/notificaciones/create",
					roles_permitidos: [1,2]
				},
				{
					icon: "mdi-account",
					title: "Salir",
					to: "/login",
					roles_permitidos: [1,2, 3, 4, 5]
				},
			],
		}),

		computed: {
			...mapState(["barColor", "barImage"]),
			drawer: {
				get() {
					return this.$store.state.drawer;
				},
				set(val) {
					this.$store.commit("SET_DRAWER", val);
				},
			},
			computedItems() {
				let role_id =  this.$store.state.user.role_id;
				if(role_id === undefined){
					return []
				}
				
				let newItems = this.items.map(element => {
					return element;
					
				});
				
       /* return  newItems.filter((item) => {
			if(item.hasOwnProperty('children') && item.children.length > 0){
				item.children = this.recursiveValidateChildren(item.children, role_id);
			}
			return item.roles_permitidos ? item.roles_permitidos.includes(role_id) : false
		}); */ return this.recursiveValidateChildren(newItems, role_id);
			},
      itemsMenu(){
        return ;
      },
			profile() {
				return {
					avatar: true,
					title: this.$t("avatar"),
				};
			},
		},

		methods: {
			recursiveValidateChildren(children, role_id){
				
				children.forEach(element => {
					if(element.hasOwnProperty('children') && element.children.length > 0){
					
					element.children = this.recursiveValidateChildren(element.children, role_id);
				}
				});
				
				return children.filter(element => element.roles_permitidos.includes(role_id));
			},
			mapItem(item) {
				return {
					...item,
					children: item.children ? item.children.map(this.mapItem) : undefined,
					title: this.$t(item.title),
				};
			},
		},
	};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
